import request from '@/utils/request'


// 查询群组成员列表
export function listGroupUser(query) {
  return request({
    url: '/user/group-user/list',
    method: 'get',
    params: query
  })
}

// 查询群组成员分页
export function pageGroupUser(query) {
  return request({
    url: '/user/group-user/page',
    method: 'get',
    params: query
  })
}

// 查询群组成员详细
export function getGroupUser(data) {
  return request({
    url: '/user/group-user/detail',
    method: 'get',
    params: data
  })
}

// 新增群组成员
export function addGroupUser(data) {
  return request({
    url: '/user/group-user/add',
    method: 'post',
    data: data
  })
}

// 修改群组成员
export function updateGroupUser(data) {
  return request({
    url: '/user/group-user/edit',
    method: 'post',
    data: data
  })
}

// 删除群组成员
export function delGroupUser(data) {
  return request({
    url: '/user/group-user/delete',
    method: 'post',
    data: data
  })
}
