<template>
  <a-modal :title="formTitle" :visible="visible" :footer="null" width="50%" @cancel="visible = false">
    <!--    <page-header-wrapper>-->
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="12">
              <a-form-item label="群成员昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入群成员昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="12">
              <a-form-item label="群成员账号" prop="account">
                <a-input v-model="queryParam.account" placeholder="请输入群成员账号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operations">
      </div>
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"

        :pagination="false"
        :bordered="tableBordered"
      >
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.avatar" slot="avatar" slot-scope="text, record">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <!--        <span slot="operation" slot-scope="text, record">-->
        <!--          <a @click="handleDelete(record)">-->
        <!--            <a-icon type="delete" />-->
        <!--            删除-->
        <!--          </a>-->
        <!--        </span>-->
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!--    </page-header-wrapper>-->
  </a-modal>

</template>

<script>
import { tableMixin } from '@/store/table-mixin'
import moment from 'moment'
import { pageGroupUser } from '@/api/user/groupUser'

export default {
  name: 'GroupUser',
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      formTitle: '',
      list: [],
      visible: false,
      endOpen: false,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickname: null,
        account: null,
        groupId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '账号',
          dataIndex: 'account',
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          align: 'center',
          scopedSlots: { customRender: 'avatar' }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '成员身份',
          dataIndex: 'userPosition',
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '组长'
            } else if (text == 1) {
              return '组员'
            }
          }
        },
        {
          title: '对讲设置',
          dataIndex: 'intercomSetup',
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '关'
            } else if (text == 1) {
              return '开'
            }
          }
        },
        {
          title: '队友位置显示',
          dataIndex: 'teammatePositionDisplay',
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '关'
            } else if (text == 1) {
              return '开'
            }
          }
        },
        {
          title: '加入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: false,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    open(rowData) {
      this.queryParam.groupId = rowData.id
      this.getList()
      this.visible = true
    },
    /** 查询短视频评论列表 */
    getList () {
      this.loading = true
      pageGroupUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam.title = null
      this.queryParam.moneyType = null
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return deleteDynamic(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('video/video/comment/export', {
            ...that.queryParam
          }, `短视频评论_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
/deep/ .ant-card-body {
  padding: 0!important;
}
</style>
